
import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue'; // @ is an alias to /src
import SidebarPurchase from '@/components/sidebar-purchase.vue'; // @ is an alias to /src
import purchaseService from '@/services/purchase-services'
import PopUp from "@/components/PopUp.vue";
import Loader from "@/components/loader.vue"; // @ is an alias to /src

@Component({
  components: {
    Header,
    SidebarPurchase,
    PopUp,
    Loader
  },
})
export default class PurchaseRequisitions extends Vue {
  loading = false;
  trans: any = {}
  request_no = ""
  public payload: any = {}
  product: any = {};
  other_info: any = []
  items: any = [];
  fieldType:any=""
  error:any=""
   // For Modal
   modalShow = false;
  modalMessage: string[] = [];
  modalSimpleMessage: string = "";
  modalType = "";
  closeModal() {
    this.modalShow = false;
    this.modalMessage = [];
    this.modalType = "";
    this.modalSimpleMessage = ""
  }
  showModal(message: string, array: string[], type: "success" | "error" | "errorArray",) {
    this.modalShow = true;
    this.modalMessage = array;
    this.modalType = type;
    this.modalSimpleMessage = message
  }
  // modal end
  cancel() {
    if(this.request_no === undefined || this.request_no === ""){
      this.error = 'Please enter request number.'
      this.fieldType='cancel';
    }else if(this.payload.status === 'cancelled'){
      this.showModal('Request is already cancelled.', [], "error");
    }else{
    purchaseService.cancelPurchaseRequest(this.payload.id).then((resp) => {
      this.showModal('Cancelled Successfully', [], "success");
      this.reset();
      this.error="";
    }).catch((e) => {
        console.log('Unexpected error:', e);
        this.showModal('An unexpected error occurred', [], "error");
      });
    }
  }

  getRequest(request_no: string) {
    if(this.request_no === undefined || this.request_no === ""){
      this.error = 'Please enter request number.'
      this.fieldType='search'
    }else{
    purchaseService.getPurchaseRequest(request_no).then((resp) => {
      this.payload = resp.data
      this.items = resp.data.items
      this.other_info = resp.data.purchase_info
    }).catch((e) => {
        console.log('Unexpected error:', e);
        this.showModal('An unexpected error occurred', [], "error");
      });
    }
    }
  reset(){
    this.payload ={}
    this.items=[];
    this.request_no='';
    this.error="";
    this.other_info=[]
  }
  downloadDocument(url: string) {
    const anchor = document.createElement("a");
    const fileUrl = process.env.VUE_APP_SERVER + url;

    // Check if the URL is valid and the file exists
    fetch(fileUrl)
      .then(response => {
        if (response.status === 200) {
          // The file exists, so we can proceed with the download
          anchor.href = fileUrl;
          anchor.download = "downloaded_file_name"; // You can specify the name of the downloaded file here
          anchor.target = "_blank";

          document.body.appendChild(anchor);
          anchor.click();

          document.body.removeChild(anchor);
          this.showModal("File Downloaded",[], "success")
        } else {
          this.showModal('File not found or URL is not valid.',[], "error")
          // Handle the case where the file does not exist
        }
      })
      .catch(error => {
        // Handle other network-related errors
        this.showModal("An error occurred while fetching the file:",[], "error");
      });
  }
}
